<template>
  <div>
    {{ getFromDate() }}
  </div>
</template>
<script>
export default {
  props: ['fromDate'],
  methods: {
    getFromDate() {
      if (this.fromDate === null || "undefined" === typeof this.fromDate) {
        return this.$t('components.custom.playground.new.step-2.permanent');
      }
      return this.$moment(this.fromDate).format('L');
    }
  }
}
</script>
